import React from 'react'

import { Typography } from '@mui/material'

import Block from '../components/Block'
import Link from '../components/Link'
import SEO from '../components/SEO'
import Spacer from '../components/Spacer'

import { DefaultLayout } from '../components/DefaultLayout'

export const Head = () => <SEO title={'M2North - 404'} />

const NotFoundPage = () => {
	return (
		<DefaultLayout>
			<Block>
				<Typography variant={'h1'}>404</Typography>
				<Typography variant={'h4'}>
					Sorry, this page doesn't exist.
				</Typography>
				<Spacer size={6} />
				<Link to={'/'}>Go back to the homepage</Link>
			</Block>
		</DefaultLayout>
	)
}

export default NotFoundPage
